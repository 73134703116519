import type { RouteObject } from 'react-router-dom';
import NotFoundRoute from '~components/NotFoundRoute';
import Home from './Home';
import Glossary from './Glossary';
import Contact from './Contact';
import LegalMention from './LegalMention';

const router: RouteObject[] = [
  {
    path: '/',
    index: true,
    Component: Home,
  },
  {
    path: '/glossary',
    Component: Glossary,
  },
  {
    path: '/contact',
    Component: Contact,
  },
  {
    path: '/legal-mentions',
    Component: LegalMention,
  },
  {
    path: '*',
    Component: NotFoundRoute,
  },
];

export default router;
