import React, { useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
import { mdiMenu } from '@mdi/js';
import magicaeImg from '~assets/img/magicae-color.png?w=50&format=avif&quality=100&imagetoolsurl';

const buttonStyle = {
  color: 'white',
  display: 'block',
  borderRadius: 0,
  '&:hover': {
    borderBottom: '1px solid white',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const activeButtonStyle = {
  ...buttonStyle,
  borderBottom: '1px solid white',
  borderTop: '1px solid white',
  borderRadius: 0,
};

const linkMenuStyle = {
  position: 'relative',
  color: 'white',
  textDecoration: 'none',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: 'unset',
    '&::before': {
      transform: 'scaleX(1)',
    },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '70%',
    height: '2px',
    bottom: 0,
    left: '15%',
    backgroundColor: 'white',
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease',
  },
};

const drawerWidth = 240;

function TopBar() {
  // Setup translate
  const { t } = useTranslation();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const routes = [
    {
      displayName: t('mainTitleParts.home'),
      path: '/',
      mainSx: useMatch({ path: '/', end: true }) ? activeButtonStyle : buttonStyle,
    },
    {
      displayName: t('mainTitleParts.glossary'),
      path: '/glossary',
      mainSx: useMatch({ path: '/glossary', end: true }) ? activeButtonStyle : buttonStyle,
    },
    {
      displayName: t('mainTitleParts.author'),
      path: '/#author',
      mainSx: useMatch({ path: '/#author', end: true }) ? activeButtonStyle : buttonStyle,
    },
    {
      displayName: t('mainTitleParts.buy'),
      path: '/#buy',
      mainSx: useMatch({ path: '/#buy', end: true }) ? activeButtonStyle : buttonStyle,
    },
  ];

  return (
    <>
      <AppBar
        id="topbar"
        position="relative"
        component="nav"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundImage: 'unset', boxShadow: 'unset' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <SvgIcon>
              <path d={mdiMenu} />
            </SvgIcon>
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', sm: 'flex' }, width: '190px' }}>
            {[routes[0], routes[1]].map((item) => (
              <Button
                component={Link}
                to={item.path}
                key={item.path}
                sx={linkMenuStyle}
                disableRipple
                disableFocusRipple
                disableTouchRipple
              >
                {item.displayName}
              </Button>
            ))}
          </Stack>
          <Avatar
            src={magicaeImg}
            component={Link}
            to="/"
            alt={t('illustrations.magicae.displayName')}
            aria-label={t('mainTitleParts.home')}
            sx={{ margin: { xs: '0 0 0 -30px', sm: '0 20px' }, height: '50px' }}
          />
          <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', sm: 'flex' }, width: '190px' }}>
            {[routes[2], routes[3]].map((item) => (
              <Button
                component={Link}
                to={item.path}
                key={item.path}
                sx={linkMenuStyle}
                disableRipple
                disableFocusRipple
                disableTouchRipple
              >
                {item.displayName}
              </Button>
            ))}
          </Stack>
          <div style={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Toolbar />
            <List>
              {routes.map((item) => (
                <ListItem key={item.path} disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={item.path}>
                    <ListItemText primary={item.displayName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
    </>
  );
}

export default TopBar;
